























































import 'swiper/dist/css/swiper.css';

import Vue from 'vue';

import Recommended from '@/components/Recommended.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default Vue.extend({
	components: { Recommended, swiper, swiperSlide },
	data () {
		return {
			prevArticle: null,
			nextArticle: null,
			relatedArticles: null,
			loop: true,
			options: {
				autoHeight: true,
				slidesPerView: 3,
				spaceBetween: 15,
				navigation: {
					nextEl: '.swiper-btn.next',
					prevEl: '.swiper-btn.prev'
				},
				fadeEffect: {
					crossFade: true
				},
				breakpoints: {
					900: { slidesPerView: 2 },
					500: { slidesPerView: 1 }
				}
			}
		};
	},
	beforeMount () {
		this.fetchArticle();
		this.fetchNextArticle();
		this.fetchPrevArticle();
		this.fetchRelatedArticles();
	},
	watch: {
		'$route.params.slug' () {
			this.fetchArticle();
			this.fetchNextArticle();
			this.fetchPrevArticle();
			this.fetchRelatedArticles();
		}
	},
	computed: {
		id (): any { return this.$route.params.slug.match(/^\d+/)[0]; },
		article (): any { return this.$store.getters.article || {}; },
		lang () : any { return (this as any).$lang(); },
		shareLink () : string { return window.location.href; }
	},
	methods: {
		fetchArticle () {
			this.$store.dispatch('fetch', {
				fragment: `article/${this.id}/`,
				endpoint: 'content',
				storeKey: 'article',
				store: true
			}).then(() => { this.tagIframe(); });
		},
		fetchNextArticle () {
			this.$store.dispatch('fetch', {
				fragment: `article/${this.id}/next/`,
				endpoint: 'content'
			}).then((res:any) => { this.nextArticle = res.data; });
		},
		fetchPrevArticle () {
			this.$store.dispatch('fetch', {
				fragment: `article/${this.id}/prev/`,
				endpoint: 'content'
			}).then((res:any) => { this.prevArticle = res.data; });
		},
		fetchRelatedArticles () {
			this.$store.dispatch('fetch', {
				fragment: `article/${this.id}/related/`,
				endpoint: 'content'
			}).then((res:any) => { this.relatedArticles = res.data; });
		},
		tagIframe () {
			// const iframe = this.$refs.article.querySelector('p iframe');
			const iframe = document.querySelector('.article p iframe');
			if (iframe && iframe.parentElement) {
				iframe.parentElement.classList.add('full-width-iframe');
			}
		}
	}
});
